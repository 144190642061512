/*desktop screen*/

.main-header .man-logo img {
    width: 100%;
    max-width: 194px;
}

.main-header .man-logo a {
    display: block;
}

.mian-header .secrch-bar input {
    background: #FFF;
    border: none;
    box-shadow: none;
    color: #939393;
    font-size: 16px;
    font-family: Roboto;
    padding-right: 160px;
    max-width: 100%;
    width: 100%;
    height: 57px;
}

.mian-header .secrch-bar button {
    padding: 6px 25px;
}

.mian-header .secrch-bar button svg {
    max-width: 30px;
    margin-right: 8px;
}

.mian-header .account-section .nav-item li {
    font-size: 15px;
}

.mian-header .account-section .nav-item li i {
    margin-left: 4px;
}

.mian-header .account-section .nav-item .nav-link svg {
    margin-right: 7px;
}

.mian-header .account-section .acoount-bar {
    column-gap: 15px;
}

.annusment-section .mobile-mar {
    display: block;
    position: relative;
    width: 100%;
}

.annusment-section .mobile-mar:hover .main-annusment-bar {
    animation-play-state: paused;
}

.main-banner .tab-content .tab-pane {
    border-radius: 8px;
}

.main-banner .nav-pills {
    margin-bottom: -8px;
    padding-top: 8px;
    position: relative;
    z-index: 1;
}

.main-banner .nav-pills .nav-item .nav-link {
    padding: 14px 17px 19px;
}

.main-banner .nav-pills .nav-item .nav-link.active {
    position: relative;
    bottom: 3px;
}

.main-banner .tab-content {
    border-radius: 8px;
    position: relative;
    z-index: 1;
}

.navigation-bar .navigation-list {
    column-gap: 40px;
}

.main-banner .banner-under-data .mian-inner-data-tab .selectMainDiv>div>div svg {
    fill: rgb(68 68 68);
    width: 18px;
    height: auto;
}

.main-banner .banner-under-data .tab-content .serch-btn svg {
    width: 30px;
    margin-right: 9px;
    vertical-align: text-top;
}

.main-banner .banner-under-data .tab-content .serch-btn {
    border: 1px solid #E81C2E;
    height: auto;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.categoriesSectionMain>.container>.row {
    margin-left: -10px;
    margin-right: -10px;
}

.categoriesSectionMain>.container>.row>* {
    padding-left: 10px;
    padding-right: 10px;
}

.categoriesSectionMain .categrories img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
}

.categoriesSectionMain .categrories {
    border-radius: 8px;
    border: 1px solid #ECECEC !important;
    background: #FFF;
    transition: all .3s;
}

.mian-popular-banner .product-iteam {
    padding: 80px 0;
}

.mian-popular-banner .product-iteam .selling-product .product-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.mian-popular-banner .product-iteam .selling-product .product-inner-image {
    /* background-color: #f0f0f0; */
}

.mian-popular-banner .product-iteam .selling-product .product-inner-image img {
    width: 100%;
    max-width: 100%;
    max-height: 168px;
    min-height: 168px;
    object-fit: contain;
}

.categoriesSectionMain p strong {
    font-size: 26px !important;
}

.categoriesSectionMain p {
    text-align: center;
}

.main-tires-section>.container>.row>div>a {
    display: inline-block;
    height: 100%;
}

.main-tires-section .card-tyr {
    height: 100%;
    margin: 0;
}

.main-tires-section .card-tyr .tyre-imge-upper {
    height: auto;
}

.main-tires-section .card-tyr .tyre-imge-upper .image-product-tyre {
    width: 100%;
    max-width: 100%;
    min-height: 165px;
    max-height: 165px;
    object-fit: cover;
}

.main-tires-section .card-tyr .cart-body h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.main-tires-section .card-tyr .cart-body .price-cart {
    column-gap: 15px;
}

.main-tires-section .card-tyr .cart-body .price-cart .cart-btn img {
    max-width: 60px;
    height: auto;
}

.main-tires-section .row.mt-5 {
    margin-top: 30px !important;
}

.selectedFilters .clearFilterStyle:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
}

.filters-sort-by p.px-5 {
    margin: 0;
    padding: 0 15px !important;
}

.filters-sort-by {
    align-items: center;
    margin-bottom: 10px;
    margin-top: 5px;
}

.main-outer-filter {
    margin-top: 40px;
}

.filters-sort-by p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #272727;
}

.filters-sort-by select {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #272727;
    box-shadow: none !important;
}

.filters-sort-by select:focus {
    border-color: #a3a3a3;
}

.car-selection-title strong {
    font-family: Roboto;
    color: #272727;
    font-size: 28px !important;
}

.Drivetrain-cart .cart-body .description {
    font-size: 14px;
    font-weight: 400;
    color: #272727 !important;
    font-family: Roboto;
    -webkit-line-clamp: 4;
    min-height: 67.19px;
}

.Drivetrain-cart .price-cart .price strong {
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

.Drivetrain-cart .price-cart>.price {
    max-width: 20%;
}

.Drivetrain-cart .price-cart>.price span {
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.Drivetrain-cart .price-cart {
    column-gap: 15px;
}

.Drivetrain-cart .cart-btn a {
    display: block;
}

.Drivetrain-cart .cart-btn img {
    width: 100%;
    max-width: 55px;
    height: auto;
    object-fit: cover;
}

.car-selection-title {
    padding: 0 0 10px;
    align-items: center;
    column-gap: 50px;
}

.main-outer-filter .label-filter {
    position: relative;
    flex-direction: row-reverse;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
}

.main-outer-filter .label-filter input {
    padding: 0;
    height: initial;
    width: initial;
    margin: 0;
    display: none;
    cursor: pointer;
}

.main-outer-filter .custome-checkboxes {
    position: relative;
    cursor: pointer;
}

.main-outer-filter input:checked+.custome-checkboxes::before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 8px;
    width: 6px;
    height: 12px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    z-index: 1;
}

.main-outer-filter input:checked+.custome-checkboxes::after {
    background-color: #e81c2e;
    border: 1px solid #e81c2e;
}

.main-outer-filter .custome-checkboxes::after {
    content: '';
    -webkit-appearance: none;
    border-radius: 4px;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid #c9c9c9;
    z-index: 0;
}

.price-filter ul li {
    padding-bottom: 10px;
}

.main-banner .nav-pills .nav-item .nav-link i {
    display: none;
}

.pagination .page-item.active .page-link {
    background-color: #e81c2e;
    border-color: #e81c2e;
}

.secrch-bar .searchIconOpen,
.secrch-bar .searchIconClose {
    display: none;
}

.main-outer-filter .aside-price-range-form .form-field:last-child input.form-control::placeholder {
    text-align: right;
}

.main-outer-filter .aside-price-range-form .form-field input.form-control {
    font-size: 14px;
    font-weight: 600;
    color: #474747;
    font-family: Roboto;
}

.main-outer-filter .aside-price-range-form .form-field:last-child input {
    text-align: right;
}

.main-outer-filter .aside-price-range-form .form-field input.form-control {
    font-size: 14px;
    font-weight: 600;
    color: #474747;
}

.main-outer-filter .aside-price-range-form .form-field input.form-control {
    border: none;
    background: none;
    padding: 0;
    font-size: 14px;
}

.main-outer-filter .aside-price-range-form .form-field {
    margin: 0 !important;
}

.main-outer-filter .aside-price-range-form .form-field input.form-control::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: #474747;
    font-family: Roboto;
}

.category-main-section {
    font-family: Roboto;
    padding-bottom: 50px;
}

button.back-to-top {
    margin: 0 !important;
    padding: 0 !important;
    background: #fff;
    height: 0px;
    width: 0px;
    overflow: hidden;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    color: transparent;
    clear: both;
    visibility: hidden;
    position: fixed;
    cursor: pointer;
    display: block;
    border: none;
    right: 50px;
    bottom: 35px;
    font-size: 0px;
    outline: 0 !important;
    z-index: 99;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

button.back-to-top.show {
    display: block;
    background: #fff;
    color: #e81c2e;
    font-size: 25px;
    right: 90px;
    bottom: 5px;
    height: 50px;
    width: 50px;
    visibility: visible;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
}

button.back-to-top::after,
button.back-to-top::before {
    content: "";
    display: block;
    vertical-align: middle;
    border-bottom: solid 10px #EA5D5F;
    border-left: solid 10px transparent;
    line-height: 0;
    border-right: solid 10px transparent;
    height: 0;
    margin: 18px auto 0;
    width: 0;
    border-radius: 20px;
    visibility: hidden;
}

button.back-to-top::after {
    border-bottom-color: #fff;
    position: relative;
    top: -24px;
}

button.back-to-top.show::after,
button.back-to-top.show::before {
    visibility: visible;
}

.add_vehicle_mobile {
    display: none;
}

.category-main-section>.container>.row {
    row-gap: 20px;
}

.category-main-section .card {
    height: 100%;
    margin-top: 0 !important;
    cursor: pointer;
}

.main-section-lsting .filter-for-mobile>.row {
    row-gap: 20px;
}

.logo {
    width: 15%;
}

.account-section {
    padding-left: 12px;
}

.main-outer-filter .price-filter ul {
    max-height: 250px;
    overflow-x: auto;
}

.main-outer-filter .price-filter ul::-webkit-scrollbar {
    width: 6px;
    display: block;
}

.main-outer-filter .price-filter ul::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.main-outer-filter .price-filter ul::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.main-outer-filter .price-filter ul::-webkit-scrollbar-thumb:hover {
    background: #555;
}

p.car-title {
    font-family: Roboto;
    color: #272727;
    font-size: 28px;
    font-weight: bolder;
    padding-top: 14px;
    margin-bottom: 10px;
}

.carTitle {
    font-family: Roboto;
    color: #272727;
    font-size: 28px;
    font-weight: bolder;
    padding-top: 14px;
    margin-bottom: 10px;
}

.mt-5.category-main-section {
    margin-top: 0 !important;
}

.category-main-section>.container.mt-5 {
    margin-top: 0 !important;
}

.product-name-details .prod_cat {
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    color: #212529;
    margin-bottom: .5rem;
    margin-top: 0;
    display: inline-block;
}

.subCategoryNameDetailsPage .sub_cat_name {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    color: #212529;
    display: block;
}

.producDescription strong {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 2px;
    display: inline-block;
}

.producDescription p {
    font-family: Roboto;
    font-size: 15px;
}

.card-tyr.smilar-product {
    height: 100%;
}

.selectMainDivEditPopup select.form-control {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: hsl(0, 0%, 20%);
}

.selectMainDivEditPopup select.form-control:focus {
    border-color: #2684FF;
    box-shadow: none;
    border-width: 2px;
}

.product-name-details .stock h4 {
    white-space: nowrap;
}

.mian-inner-data-tab .selectMainDivEditPopup {
    font-family: Roboto;
}

.mian-inner-data-tab button.btn {
    font-size: 15px;
    font-family: Roboto;
    display: inline-flex;
    align-items: center;
    box-shadow: none;
}

.mian-inner-data-tab button.btn svg {
    margin-right: 5px;
    width: 30px;
}

.modal-header .modal-title {
    font-family: Roboto;
    font-size: 22px;
    line-height: 31px;
}

.mian-inner-data-tab button.btn:active:focus {
    box-shadow: none;
}

.mian-inner-data-tab button.btn.restButton {
    background: #535353;
    border-color: #535353;
    box-shadow: none;
}

.mian-inner-data-tab button.btn:hover {
    background: #fff;
    color: #b02a37;
}

.mian-inner-data-tab button.btn:hover svg path {
    fill: #b02a37;
}

.mian-inner-data-tab button.btn.restButton:hover {
    background: #fff;
    color: #535353;
}

.mian-inner-data-tab button.btn.restButton:hover svg path {
    fill: #535353;
}

.mian-inner-data-tab button.btn svg path {
    transition: all .4s;
}

.mian-inner-data-tab button.btn.restButton i {
    margin-right: 7px;
    font-size: 19px;
}

.about-us-content .banner_image img {
    width: 100%;
    max-width: 100%;
    max-height: 620px;
    object-fit: cover;
}

.about-us-content {
    padding: 0 0 10px;
}

.about-us-content .content_first h2 {
    font-family: Roboto;
    font-size: 35px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0em;
    color: #272727;
    padding-bottom: 25px;
    text-align: center;
    margin: 0;
}

.about-us-content .content_first p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: #595959;
    margin-bottom: 18px;
}

.about-us-content .banner_image {
    margin: 0 0 50px;
}

.about-us-content .content_second h3 {
    font-family: Roboto;
    font-size: 28px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0em;
    color: #272727;
    margin-bottom: 25px;
    text-align: left;
}

.about-us-content .content_second ul {
    padding-left: 0;
}

.about-us-content .content_second li {
    list-style-type: none;
    padding-bottom: 16px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #595959;
    padding-left: 36px;
    position: relative;
}

.about-us-content .content_second li::before {
    content: '';
    position: absolute;
    background-image: url('../public/images/check.svg');
    width: 21px;
    height: 21px;
    left: 0;
    top: 4px;
}

.warrent-banner.contact_us_banner {
    background-image: url('../public/images/contact_banner.jpg');
}

.contact_us_addr {
    background: #F2F2F2;
    padding: 80px 0;
}

.contact_us_addr .addr_title {
    color: #272727;
    text-align: center;
    font-family: Roboto;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    margin-bottom: 30px;
}

.contact_us_addr .main .box {
    border-radius: 6px;
    border: 1px solid #E8E8E8;
    background: #FFF;
    padding: 35px 35px 25px;
    height: 100%;
    text-align: center;
}

.contact_us_addr .box .content h3 {
    color: #272727;
    text-align: center;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 12px;
}

.contact_us_addr .box .content p,
.contact_us_addr .box .content a {
    margin: 0;
    color: #595959;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
}

.contact_form {
    background: #FFF;
    padding: 78px 0 75px;
}

.contact_form .main .form_title {
    color: #272727;
    text-align: center;
    font-family: Roboto;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 16px;
}

.contact_form .main>p {
    color: #595959;
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
}

.contact_form .main form label.form_field {
    width: 100%;
    margin-bottom: 10px;
}

.contact_form .main form label.form_field input,
.contact_form .main form label.form_field textarea {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #E8E8E8;
    background: #FFF;
    padding: 20px;
    outline: none;
    line-height: 18px;
}

.contact_form .main form input[type="submit"] {
    border-radius: 6px;
    background: #E81C2E;
    color: #FFF;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* padding: 20px; */
    padding: 14px 8px 14px 7px;
    width: 100%;
    border: 1px solid #E81C2E;
}

.contact_form .main form input[type="submit"]:hover {
    background: #fff;
    color: #E81C2E;
}

.contact_form .main form {
    margin-top: 30px;
}

.contact_form .main form label.form_field textarea {
    max-height: 122px;
}

.contact_map iframe {
    width: 100%;
    filter: brightness(0.6);
}

.shipingg a {
    color: rgba(232, 28, 46, 1);
}

.warnnty-text .contact-anker {
    padding-left: 5px;
}

.listing-parmofamne li:not(:first-child) {
    padding-left: 5px;
}

/*Cart Page*/
.cart-main .cart-body {
    padding: 0;
}

.cart-main .cart_title .title {
    font-family: Roboto;
    font-size: 26px;
    font-weight: 700;
    line-height: 40px;
    padding-top: 30px;
    margin-bottom: 20px;
}

.cart-main .cart-box {
    padding: 10px;
    position: relative;
    border-radius: 5px;
    margin-bottom: 22px;
    border: 1px solid #E8E8E8;
}

.cart-main .cart-box .close_btn {
    position: absolute;
    right: 15px;
    top: 15px;

    /* CSS for highlighting in red */
    .highlight-red {
        border: 1px solid red;
        /* You can adjust the styling as needed */
        background-color: #ffcccc;
        /* You can adjust the background color as needed */
    }

}

.cart-main .cart-box .close_btn a {
    color: #212529;
    cursor: pointer;
}

.col-md-8 .cart-box strong {
    margin-bottom: 5px;
}

.cart-box .img_content {
    display: flex;
    align-items: flex-start;
}

.img_content .product_image {
    width: 120px;
    height: 120px;
    object-fit: cover;
}

.img_content .product_details {
    width: calc(100% - 140px);
    padding-left: 10px;
}

.img_content .product_image {
    width: 140px;
    height: 140px;
    object-fit: cover;
    padding-right: 10px;
    border-right: 1px solid #eee;
}

.img_content .product_image img {
    width: 100%;
    object-fit: contain;
    padding: 5px;
}

.product_details .title {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
    padding-right: 14px;
    width: 91%;
}

.product_details .part_number {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #595959;
    margin-bottom: 8px;
}

.product_details .part_number span {
    color: #E81C2E;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}

.product_details .list ul {
    padding-left: 0;
    margin-bottom: 0;
}

.product_details .list ul li {
    list-style-type: none;
    padding-bottom: 6px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    color: #595959;
    padding-left: 26px;
    position: relative;
}

.product_details .list ul li::before {
    content: '';
    position: absolute;
    background-image: url('../public/images/check.svg');
    width: 15px;
    height: 15px;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    top: 1px;
}

.product_details .qty_price {
    display: flex;
    align-items: center;
}

.product_details .qty_price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    padding-top: 10px;
    border-top: 1px solid #eee;
}

.cart-box .qty {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.cart-box .qty .qty_text {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
}

.cart-box .quantity .value-button {
    padding: 11px 0;
    width: 25px;
    height: 35px;
}

.cart-box .quantity .value-button i {
    font-size: 14px;
}

.cart-box .quantity {
    border: 1px solid #c0c0c0;
    border-radius: 6px;
    padding: 0 5px;
}

.cart-box .quantity input#number {
    width: 50px;
    height: auto;
    font-size: 22px;
    line-height: normal;
    border-left: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0;
}

.cart-box .qty .update_cart a {
    font-size: 14px;
    display: inline-block;
    height: 35px;
    line-height: 34px;
    border-radius: 6px;
    background: #fff;
    padding: 0 12px;
    color: #212529;
    border: 1px solid #212529;
    text-decoration: none;
    font-weight: 500;
}

.cart-box .qty .update_cart a:hover {
    background: #212529;
    color: #fff;
}

.cart-box .quantity .value-button:hover i {
    color: #212529;
}

.product_details .price span {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    color: #212529;
}

.total-main {
    padding: 20px;
    position: relative;
    border-radius: 6px;
    border: 1px solid #E8E8E8;
}

.total-main .title {
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
}

.total-main .title h2 {
    margin: 0;
    font-family: Roboto;
    font-size: 25px;
    font-weight: 500;
    line-height: normal;
}

.total-main .details {
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
}

.total-main .subtotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.total-main .discount_items {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.total-main .delivery {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.total-main .details span {
    font-size: 16px;
    font-family: Roboto;
    color: #212529;
    padding-bottom: 2px;
    font-weight: 500;
}

.total-main .discount_items span {
    font-size: 16px;
    font-family: Roboto;
    color: #212529;
    padding-bottom: 2px;
    font-weight: 600;
}

.total-main .details .price {
    font-weight: 400;
}

.total-main .totals {
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
}

.total-main .totals .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.total-main .totals span {
    margin: 0;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
}

.total-main .proceed_btn a {
    text-align: center;
    display: block;
    background: #e81c2e;
    border: 1px solid #e81c2e;
    color: #fff;
    padding: 15px;
    border-radius: 6px;
    margin: 25px 0 20px;
    text-decoration: none;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.total-main .proceed_btn a:hover {
    /* color: #e81c2e; */
    /* background: #fff; */
}

.total-main .policy span {
    text-align: left;
    display: block;
    color: #595959;
    font-size: 14px;
}

.total-main .policy span a {
    color: #e81c2e;
}

.img_content .product_details .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list .product_price {
    margin: 0;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    color: #e81c2e;
}

/*Cart Page End*/
.wheelsPageSectioin .price-filter .tgl-btn {
    height: 28px;
    width: 52px;
    background: green;
}

.wheelsPageSectioin .price-filter .tgl-btn::after {
    width: 24px;
    background: #ffffff;
}

.wheelsPageSectioin .price-filter .tgl-light:checked+.tgl-btn {
    background: #E81C2E;
    border-color: #E81C2E;
}

.mainDivStaggered {
    justify-content: end;
}

.staggeredCheckbox .price-filter {
    margin-bottom: 0;
    border: none;
    padding: 0;
}

.staggeredCheckbox .price-filter .list-filter {
    display: flex;
    align-items: center;
}

.staggeredCheckbox .list-filter li {
    padding: 0;
}

.wheelsPageSectioin .price-filter .tgl-btn {
    height: 28px;
    width: 52px;

}

.wheelsPageSectioin .price-filter .tgl-btn::after {
    width: 23px;
    background: #fff;
}

.mainDivStaggered .textShowing {
    display: flex;
    align-items: center;
}

.wheelsPageDiv .wheelsPage.leftSide .cart-body {
    margin: 10px 16px 0;
    padding: 10px 0 6px;
    border-top: 1px solid #21252930;
}

.wheelsPage.leftSide .cart-body p {
    font-size: 14px;
    color: #212529;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-bottom: 1px;
}

.wheelsPage.rightSide p {
    margin-bottom: 1px;
}

.wheelsPage.rightSide p strong {
    margin-right: 12px;
}

.wheelsPage.rightSide p strong.text-danger {
    font-size: 18px;
    display: inline-block;
    margin-top: 2px;
    margin-right: 5px;
}

.wheelsPage.rightSide .priceWheel:last-child strong {
    margin-left: 5px;
}

.wheelsPage.rightSide .priceWheel {
    font-weight: 600;
}

.wheelsPage.rightSide p strong.text-danger {
    font-size: 18px;
    display: inline-block;
    margin-top: 2px;
    margin-right: 5px;
    position: relative;
    top: 1px;
}

.wheelsPage.rightSide .priceWheel:last-child strong {
    margin-left: 5px;
}

.wheelsPage.leftSide .cart-body p strong {
    margin-right: 5px;
}

.staggeredCheckbox .list-filter .staggeredLi {
    font-family: Roboto;
    color: #272727;
    font-size: 18px;
    font-weight: 600;
}

.wheelsPage.leftSide .engle-imge-upper {
    cursor: pointer;
}

.social-icons a {
    color: #272727;
    text-decoration: none;
}

.mian-header .account-section .acoount-bar .nav-item {
    position: relative;
    cursor: pointer;
}

.nav-item .cartValue {
    font-size: 10px;
    font-weight: 700;
    position: absolute;
    top: -9px;
    left: 14px;
    background: #e81c2e;
    border-radius: 50%;
    border: 1px solid #e81c2e;
    width: 16px;
    height: 16px;
    color: #fff !important;
    line-height: 13px;
    text-align: center;
}

.blogs_main {
    margin: 100px 0 20px;
}

.blogs_main .blog_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;
}

.blog_row .blo_col {
    width: 100%;
    max-width: 33.33%;
    padding: 0 15px;
    margin-bottom: 60px;
}

.blog_box .blog_img img {
    width: 100%;
    max-width: 100%;
    min-height: 270px;
    max-height: 270px;
    object-fit: cover;
}

.blog_box {
    border: 1px solid rgba(232, 232, 232, 1);
    background: #fff;
    border-radius: 6px;
    overflow: hidden;
}

.blog_box .blog_content {
    padding: 25px 20px;
}

.blog_box .blog_content h2 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: #272727;
    margin-bottom: 8px;
}

.blog_box .blog_content .date {
    font-family: Roboto;
    color: #212529;
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    margin-bottom: 8px;
    display: inline-block;
}

.blog_box .blog_content .content {
    font-family: Roboto;
    color: #212529;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.blog_content .readmore {
    font-family: Roboto;
    color: #dc3545;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 500;
}

/*Blog Details*/
.warrent-banner.blog-details {
    background-image: none;
    height: auto;
}

.warrent-banner.blog-details .parformane-bar {
    background-color: #282828;
}

.blog_detail_main {
    margin: 75px 0 50px;
}

.blog_detail_main .container {
    max-width: 1140px;
}

.blog_detail_main .blog_title {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    color: #272727;
    margin-bottom: 12px;
}

.blog_detail_main .dev_details {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 20px;
}

.dev_details .author {
    font-family: Roboto;
    color: #595959;
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
}

.dev_details .date {
    font-family: Roboto;
    color: #595959;
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    text-transform: capitalize;
}

.dev_details .category {
    font-family: Roboto;
    color: #595959;
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    text-transform: capitalize;
}

.dev_details .commnt {
    font-family: Roboto;
    color: #595959;
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    text-transform: capitalize;
}

.dev_details span:not(:last-child) {
    position: relative;
}

.dev_details span:not(:last-child)::after {
    content: '';
    position: absolute;
    background: #595959;
    height: 90%;
    width: 1px;
    right: -5.5px;
    left: auto;
    bottom: 0;
}

.blog_detail_main .blog_banner img {
    width: 100%;
    max-width: 100%;
    max-height: 690px;
    object-fit: cover;
}

.blog_detail_main .blog_banner {
    margin-bottom: 35px;
}

.blog_content .main_title {
    font-family: Roboto;
    font-size: 26px;
    font-weight: 700;
    line-height: normal;
    color: #272727;
    margin-bottom: 12px;
}

.blog_content p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 15px;
    color: #595959;
}

.blog_content img {
    margin: 20px 0;
    width: 100%;
    max-width: 100%;
}

.blog_content .sub_headings {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    color: #272727;
    margin: 14px 0;
}

.blog_content ul {
    padding-left: 30px;
    margin-bottom: 12px;
}

.blog_content ul li {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    color: #595959;
    line-height: 29px;
}

.blog_content ul li b {
    padding-right: 2px;
}

.blog_content a {
    color: #e81c2e;
    font-family: Roboto;
}

.blog_content p.italic {
    font-style: italic;
}

.blog_content p.italic {
    font-style: italic;
    color: #8f8f8f;
}

.cart-main .cart-body>.row {
    justify-content: center;
}

.Vy94J0 img._2giOt4 {
    width: 100%;
    max-width: 100%;
    max-height: 160px;
    object-fit: contain;
}

.Vy94J0 ._1LCJ1U {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    padding-top: 25px;
    margin-bottom: 15px;
    text-align: center;
}

.Vy94J0 .hKIFfL {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 15px;
    text-align: center;
}

.Vy94J0 {
    text-align: center;
    margin-bottom: 50px;
}

.Vy94J0 button._2KpZ6l {
    background: none;
    margin-top: 10px;
}

.Vy94J0 button._2KpZ6l a {
    background-color: #e81c2e;
    font-family: Roboto;
    color: #fff !important;
    padding: 10px 20px;
    font-size: 15px;
    border-radius: 6px;
    display: block;
}

.warrent-banner.about_us {
    background-image: none;
    height: auto;
}

.warrent-banner.about_us .parformane-bar {
    background-color: #282828;
}

.about-us-content .content_first {
    margin-bottom: 65px;
}

.mian-header .secrch-bar button img.wheel_gif_icon {
    display: none;
    margin-right: 5px;
    width: 33px;
    height: 23px;
    object-fit: contain;
}

.mian-header .secrch-bar button:hover svg {
    display: none;
}

.mian-header .secrch-bar button:hover img.wheel_gif_icon {
    display: inline-block;
}

.also-bought .cart-body .also_bought_cat_name {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #212529;
    font-family: Roboto;
    display: block;
}

.providing img {
    width: 100%;
    max-width: 100%;
    max-height: 87px;
}

.payment_icons img {
    width: auto;
    max-width: 100%;
    max-height: 32px;
}

.chat-bot img {
    width: 34px;
    height: 34px;
}

.main-copy-right>.container>.row {
    align-items: center;
}

p.copy-right {
    margin: 0;
}

.mainAttributes .iconImage img {
    width: 24px;
    height: 24px;
}

.product-data span.product-cate {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 11px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 0.5rem;
    display: block;
}

.account-section .acoount-bar .nav-item .cartTitle {
    color: #272727;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 2px;
}

.card-tyr .top_sell_cat {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #212529;
    font-family: Roboto;
    display: block;
}

.main-tires-section .card-tyr .cart-body .top_sell_prod_title {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 800;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.main-tires-section .card-tyr .cart-body .price .top_sell_prod_price {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 800;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #e81c2e;
}

.under-data-srech h3 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
}

.main-tires-section .top_sell_cat_text {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 800;
    line-height: 1.2;
    color: #212529;
    font-family: Roboto;
    display: block;
}

.main-top-brands .brandPageTile img {
    max-width: 170px;
    object-fit: contain;
}

.category-main-section .card-title {
    margin-bottom: .5rem;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    font-family: Roboto;
    color: #212529;
}

.category-main-section h3 {
    font-size: 24px;
}

.contact_us_addr .box .icon img {
    max-width: 89px;
}

.mian-vernty-img img {
    max-width: 100%;
    height: auto;
    padding-right: 50px;
}

.warnnty-text .check-text img {
    width: 20px;
    height: 20px;
}

.shipingg .points {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
    padding: 0;
}

.shipingg .points img {
    width: 21px;
    height: 21px;
}

.blog_box .blog_content h2 a {
    color: #272727;
    text-decoration: none;
}

.main-banner button.serch-btn img.wheel_gif_icon {
    display: none;
    margin-right: 6px;
    width: 33px;
    height: 23px;
    object-fit: contain;
}

.main-banner button.serch-btn:hover svg {
    display: none;
}

.main-banner button.serch-btn:hover img.wheel_gif_icon {
    display: inline-block;
}

.mian-serch-section .secrch-bar button img.wheel_gif_icon {
    display: none;
    margin-right: 5px;
    width: 35px;
    height: 25px;
    position: relative;
    object-fit: contain;
    top: 0;
}

.mian-serch-section .secrch-bar button:hover svg {
    display: none;
}

.mian-serch-section .secrch-bar button:hover img.wheel_gif_icon {
    display: inline-block;
}

.mian-serch-section .secrch-bar button svg {
    margin-right: 8px;
}

.mian-inner-data-tab button.srch_btn img.wheel_gif_icon {
    display: none;
    margin-right: 2px;
    width: 33px;
    height: 23px;
    object-fit: contain;
}

.mian-inner-data-tab button.srch_btn:hover svg {
    display: none;
}

.mian-inner-data-tab button.srch_btn:hover img.wheel_gif_icon {
    display: inline-block;
}

.main-tires-section>.container>.row {
    row-gap: 30px;
}

.main-tires-section .engle-imge-upper button.hover-btn {
    width: 100%;
    max-width: max-content;
}

.main-top-brands .brand-filters ul.alphabet-list {
    padding: 0;
    list-style: none;
    padding-top: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-top-brands .brand-filters .alphabet-list li {
    display: inline-block;
    padding: 9px 16px;
    border: 1px solid #dddddd;
    background: #fff;
    cursor: pointer;
    font-family: Roboto;
    color: #212529;
    border-radius: 4px;
    text-align: center;
}

.main-top-brands .brand-filters .alphabet-list li.selected {
    color: #e81c2e;
    border-color: #e81c2e;
}

.main-top-brands .searchInput input {
    box-shadow: none;
    outline: none;
    border: 1px solid #dddddd;
    background: #fff;
    cursor: pointer;
    font-family: Roboto;
    color: #212529;
    font-size: 16px;
    padding: 10px 30px 10px 15px;
    border-radius: 4px;
}

.main-top-brands .image_brands {
    background: #fff;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dddddd;
}

.main-top-brands .text-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.main-top-brands .text-search .text {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    text-transform: capitalize;
    color: #212529;
    max-width: 50%;
}

.main-top-brands .text-search .searchInput {
    max-width: 50%;
}

.main-top-brands .brandPageTile {
    margin-top: 0 !important;
}

.main-top-brands>.container .row {
    row-gap: 20px;
}

.main-top-brands .section-heading {
    font-family: Roboto;
    font-size: 22px;
    margin-bottom: 2px;
    margin-top: 16px;
}

.stockCheckbox .stockCheckboxUl {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 15px;
    list-style: none;
}

.stockCheckbox .stockCheckboxUl .staggeredLi {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #272727;
}

.stockCheckbox .stockCheckboxUl label.tgl-btn {
    height: 26px;
    width: 48px;
    background: green;
}

.stockCheckbox .stockCheckboxUl .tgl-light:checked+label.tgl-btn {
    background: #E81C2E;
}

.stockCheckbox .stockCheckboxUl label.tgl-btn::after {
    width: 22px;
}

.stockCheckbox .stockCheckboxUl {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 15px;
    list-style: none;
}

.Drivetrain-cart .price .price_tag {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 800;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #e81c2e;
}

.cart_on_mobile {
    display: none;
}

.dropdown-category button.dropdown-toggle {
    padding: 10px;
    height: 49px;
    box-shadow: none;
    outline: none;
    position: static;
    margin: 0;
    font-family: Roboto;
    font-size: 16px;
    color: #fff;
    margin-left: 4px;
    border-radius: 6px;
}

.dropdown-category button.dropdown-toggle:hover {
    background: #e81c2e;
    color: #fff;
}

.dropdown-category .dropdown-menu {
    position: absolute;
    z-index: 1000;
    min-width: 50%;
    padding: 15px;
    margin: 0;
    top: 59px;
    left: 4px;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(232, 232, 232, 1);
    border-radius: 0 0 4px 4px;
}

.dropdown-category .dropdown-menu li a {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    text-transform: capitalize;
    color: #272727;
    margin: 5px 0 0;
    display: block;
}

.dropdown-category .dropdown-menu li a:hover {
    text-decoration: none;
    color: #e81c2e;
}

.dropdown-category .dropdown-menu li:not(:last-child) {
    border-bottom: 1px solid rgba(232, 232, 232, 1);
    padding-bottom: 6px;
}

.shipingg.return_ploicy_title .points {
    font-size: 26px;
}

.shipingg.return_ploicy_title {
    padding-left: 44px;
    margin: 70px 0;
}

.siderr-bar {
    position: relative;
}

.categoriesSectionMain .col-lg-2>a .categrories {
    border: none;
}

/*Search Sidebar*/
.search_opener {
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    right: 0;
    transition: .4s;
    width: 100%;
    height: 100%;
    z-index: 1800;
    left: 100%;
}

.search_opener .search_box_main {
    background: #fff;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 500px;
    min-width: 500px;
    padding: 20px;
    overflow-x: auto;
}

body.open_search {
    overflow-y: hidden;
    overflow-x: hidden;
}

body.open_search .fade_background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 40%);
    z-index: 1500;
}

.search_opener.active {
    left: 0;
}

.search-closer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
}

.secrch-bar .dropdown-category {
    display: none;
}

.search_box_main button {
    display: none;
}

.search_opener .search_box_main .dropdown-category {
    display: block;
}

.search_opener .search_box_main .dropdown-category button {
    display: block;
    height: 46px;
    pointer-events: all !important;
}

.search_box_main .result-div .itemDiv {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    text-transform: capitalize;
    color: #272727;
}

.search_box_main .result-div .itemDiv span {
    padding-right: 10px;
}

.search_box_main .dropdown-category span#search_concept {
    font-size: 15px;
    font-weight: 500;
}

.search_opener .search_box_main input::placeholder {
    color: #575757;
}

.search_opener .search_box_main input {
    padding-right: 20px;
    color: #272727;
    height: 54px;
}

.search_box_main .searc_heading p {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
    color: #272727;
    margin-bottom: 15px;
    padding: 0 5px;
}

.search_banners .searc_heading.second {
    width: 100%;
}

.search_banners .banner_box {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: calc(50% - 7.5px);
    column-gap: 10px;
    border: 1px solid rgba(232, 232, 232, 1);
    margin-top: 15px;
    border-radius: 6px;
    justify-content: start;
    padding: 5px;
    cursor: pointer;
    transition: all .3s;
}

.search_banners .banner_box:hover {
    transform: scale(1.05);
}

.search_banners .banner_img img {
    width: 100%;
    max-width: 82px;
    min-width: 82px;
    min-height: 82px;
    max-height: 82px;
    object-fit: contain;
    padding: 0 2px;
}

.search_banners .banner_text h2 {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    line-height: normal;
    color: #272727;
    margin-bottom: 0;
    padding: 0 5px;
}

.search_banners {
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
}

.search_banners .searc_heading.second p {
    font-size: 18px;
    margin-bottom: 5px;
}

.search_box_main img.search_side_gif {
    width: 100%;
    max-width: 40px;
    margin-right: 4px;
}

.search_box_main .result-div .produc-list {
    border: 1px solid #E8E8E8;
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: start;
    margin-top: 15px;
    padding: 12px 5px 5px;
    column-gap: 15px;
    cursor: pointer;
}

.search_box_main .result-div .produc-list .image img {
    max-width: 82px;
    min-width: 82px;
    min-height: 82px;
    max-height: 82px;
    object-fit: contain;
}

.search_box_main .result-div .produc-list .text p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
    margin-bottom: 0;
}

.search_box_main .result-div .produc-list .text p+p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
}

.search_box_main .result-div {
    height: calc(100vh - 210px);
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 0 !important;
    padding-right: 5px;
    overflow: auto;
}

.search_box_main .result-div::-webkit-scrollbar {
    width: 6px;
}

.search_box_main .result-div::-webkit-scrollbar-track {
    background: #f1f1f1;
    width: 10px;
}

.search_box_main .result-div::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

.search_box_main .result-div .produc-list:first-child {
    margin-top: 0;
}

.result-div .text .price-cart .price.mt-2 {
    line-height: 18px;
    margin-top: 0 !important;
}

.result-div .text .price-cart .price.mt-2 strong {
    font-size: 13px;
    font-family: Roboto;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    color: #272727;
}

.search_box_main .result-div .produc-list .image+div {
    width: 100%;
}

.result-div .text .price-cart .price h5 {
    font-size: 15px;
}

.result-div .text>.price-cart+.price-cart {
    align-items: baseline;
}

.result-div .text .price-cart .cart-btn img {
    min-width: 38px;
    max-width: 38px;
    min-height: 38px;
    max-height: 38px;
    object-fit: contain;
}

.search_box_main>p {
    padding: 5px 5px 0;
    margin: 5px 0 0 0;
    font-family: Roboto;
    color: #272727;
    font-size: 15px;
    font-weight: 500;
}

.search_box_main .result-div>p {
    font-family: Roboto;
    color: #272727;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
    line-height: 22px;
}

.search_banners {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.search_banners.hidden {
    opacity: 0;
}

.search_opener .loader-line {
    max-width: 500px;
    min-width: 500px;
    left: auto;
    right: 0;
}

.mian-header .secrch-bar .form-search button {
    pointer-events: none;
}

.selectMainDiv .css-1dimb5e-singleValue {
    line-height: 40.8px;
    margin: 2px;
}

.selectMainDivEditPopup .css-1dimb5e-singleValue {}

.mian-serch-section .under-data-srech .secrch-bar.serch-bar-2 input[type="search"] {
    padding-right: 155px;
}

body::-webkit-scrollbar {
    width: 0;
}

.search_opener .search_box_main input[type="search"]::-webkit-search-cancel-button {
    opacity: 1;
    visibility: visible;
    font-size: 18px;
    cursor: pointer;
}

.search_box_main .searc_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searc_heading .stockCheckboxUl {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
}

.searc_heading .stockCheckboxUl label.tgl-btn {
    height: 24px;
    width: 44px;
    background: green;
}

.searc_heading .stockCheckboxUl input#stockCheck:checked+label {
    background: #E81C2E;
}

.searc_heading .stockCheckboxUl label.tgl-btn::after {
    width: 20px;
}

.searc_heading .stockCheckboxUl .staggeredLi {
    font-size: 12px;
    margin-left: 6px;
}

.stockCheckbox .stockCheckboxUl li {
    position: relative;
}

.stockCheckbox .stockCheckboxUl input {
    display: block;
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

body.full-screen-loader .fade_background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 40%);
    z-index: 1500;
}

body.full-screen-loader {
    overflow: hidden;
}

.fitmet-type {
    display: none;
}

.submit-button-div span {
    cursor: pointer;
}


.rs-slider-handle .rs-tooltip,
.rs-slider-handle.active .rs-tooltip {
    display: block !important;
    opacity: 1 !important;
    top: -30px !important;
}

.Drivetrain-cart .price-cart>.price.mt-2 {
    margin-top: 5px !important;
}

.Drivetrain-cart .price-cart>.price.mt-2 {
    margin: 4px 0 !important;
    max-width: 80%;
}

.wheelsPage.rightSide .priceWheel span:not(:last-child) {
    margin-right: 30px;
}

.filters-sort-by>div:first-child {
    flex: auto;
}


.form-main-div {
    display: block;
    border-radius: 4px;
    /* border: 1px #ddd solid; */
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    padding: 14px 18px;
}

.form-main-div .heading {
    font-weight: 400;
    font-size: 28px;
    line-height: 1.2;
}

.form-main-div label {
    display: block;
    padding-left: 2px;
    padding-bottom: 2px;
    font-size: 14px;
}

.form-main-div .form-label {
    margin: 0 !important;
}

.form-main-div .span-login {
    font-size: 12px;
}

.form-main-div button {
    width: 100%;
}

.logo-register-page {
    width: 100%;
    height: 8%;
    margin: 22px 0;
}


.a-divider-inner {
    height: 44px;
    margin-bottom: -18px;
    background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, .14), rgba(0, 0, 0, .03) 3px, transparent);
    background: linear-gradient(to bottom, rgba(0, 0, 0, .14), rgba(0, 0, 0, .03) 3px, transparent);
    z-index: 0;
    zoom: 1;
}

.user-template {
    position: absolute;
    right: 0;
    top: 60px;
    background: rgb(244 241 241);
    padding: 20px;
    border-radius: 5px;
    z-index: 9;
}

span.nav-action-inner.btn.btn-danger.btn-sm {
    width: 100%;
}

.nav_pop_new_cust.nav-flyout-content.nav-flyout-accessibility span:hover {
    cursor: pointer;
}

.active-user-template {
    display: none;
}

.nav_pop_new_cust.nav-flyout-content.nav-flyout-accessibility {
    font-size: 12px;
    margin-top: 10px;
}

.startHere:hover {
    cursor: pointer;
    text-decoration: underline;
}

.wheelsPageSectioin .textShowing>div:first-child {
    flex: auto;
}

.stockCheckbox .stockCheckboxUl .staggeredLi span {
    min-width: 101px;
    display: inline-block;
}

.main-header-serachbar {
    position: relative;
}

.nav-item.user-mobile {
    display: none;
}


/* CSS for highlighting in red */
.highlight-red {
    border: 1px solid red;
    /* You can adjust the styling as needed */
    background-color: #ffcccc;
    /* You can adjust the background color as needed */
}

/* CSS for changing the border color of focused, empty input fields to red and removing the default focus outline */
input:focus:empty {
    border: 2px solid red;
    /* Set the border color and style as needed */
    outline: none;
    /* Remove the default blue outline */
}


.Toastify__toast-theme--colored.Toastify__toast--info {
    background: #E81C2E !important
}

.main-tires-section .Drivetrain-cart {
    position: initial;
    padding-bottom: 0;
}

.main-tires-section .Drivetrain-cart .cart-body .price-cart:last-child {
    position: static;
}

.contact_form {
    padding: 50px 0px 10px;
}

.details .cart-box {
    margin-bottom: 6px !important;
}

.details .cart-box .all_content {
    /* width: 140px; */
    height: 80px;
    object-fit: cover;
    /* padding-right: 10px; */
    /* border-right: 1px solid #eee; */
}

.details .cart-box .all_content img {
    /* width: 100%; */
    object-fit: contain;
    padding: 5px;
}

.ribbon_box {
    position: relative;
}

/* .box {
    width: 200px; height: 300px;
    position: relative;
    border: 1px solid #BBB;
    background: #EEE;
  } */
.ribbon {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}

.ribbon span {
    font-size: 10px !important;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 18px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#F70505 0%, #8F0808 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    left: -21px;
}

.ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #8F0808;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8F0808;
}

.ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #8F0808;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8F0808;
}





.five_percent_homepage {
    position: absolute;
    right: -80px;
    top: -20px;
}

.five_percent_homepage img {
    width: 80%;
}

.five_percent_products {
    position: absolute;
    right: 45px;

}

.five_percent_products img {
    width: 75%;
}

.five_percent_details {
    position: absolute;
}

.deal_of_the_day span {

    background-color: #E81C2E !important;
    padding: 4px 10px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 4px;
    color: #fff;
    margin-left: 11px;

}

.price_in_card_one{
    font-size: 14px !important;
}
.price_in_card_two{
    font-size: 16px !important;
}

.price_card_acc{
    display: flex;
}
.price_card_acc .price_in_card_two {
    margin-right: 5px;
}


.discount_message {
    color: red;
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
    margin-left: 13%;
    text-decoration-skip-ink: none;
    text-underline-offset: 6px;
    font-family: Roboto;
}


.details-discount {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #E81C2E;
    text-align: center;
    text-decoration: underline;
    margin: 10px 0;
    text-decoration-skip-ink: none;
    text-underline-offset: 5px;
}

.cart_title {
    margin: 15px 0;
}

.wheelsPage.leftSide .engle-imge-upper img {
  margin-top: 12px;
}


.tire_logo_cart_div{
    display: flex;
    justify-content: space-between;
}


.tire_logo_cart_div .tire_logo img {
    width: 100%;
    max-width: 55px;
    height: auto;
    object-fit: cover;
}



.vehicle-details-table {
    flex: 1;
    overflow-y: auto;
    max-height: 300px;
    overflow-x: hidden;
   
  }
  
  .vehicle-details-table .row {
    display: flex;
    flex-wrap: wrap; 
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
  }
  
  .vehicle-details-table .col {
    flex: 1;
    min-width: 0; 
  }
  .vehicle-data .col{

    font-weight: bold;
  }
  
  .vehicle-details-table::-webkit-scrollbar {
    width: 10px;
  }
  
  .vehicle-details-table::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
  
  .vehicle-details-table::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 5px;
  }
  .vehicle_dt{
    font-size: 21px;

  }
  
/* mobile screen */
@media screen and (max-width:576px) {

    .form-search1 button {
        display:none;
    }

    button.back-to-top.show {
      
        right: 90px;
       
    }


    .css-1fdsijx-ValueContainer {
        text-align: left;
    }

    .fitmet-type {
        display: block;
    }

    .search_opener.active a.searchIconClose {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
        z-index: 1;
        position: static;
        margin: -5px 0 0 0;
        text-align: right;
        padding: 5px 10px 0;
    }

    .search_opener .search_box_main {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        position: relative;
        height: 100%;
        overflow-x: visible;
    }

    .main-header {
        padding: 10px 0px;
    }

    .annusment-section .mobile-mar {
        display: block;
        position: relative;
        width: 100%;
        height: 27px;
        overflow: hidden;
    }

    .annusment-section .inner-annusment-text h1 {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        white-space: nowrap;
    }

    .annusment-section .inner-annusment-button button {
        font-size: 12px;
        width: auto;
        white-space: nowrap;
        padding: 3px 8px;
        font-weight: 400;
    }

    .annusment-section .main-annusment-bar {
        width: 320%;
        position: absolute;
        /* overflow: hidden; */
        animation: marquee 12s linear infinite;
    }

    .logo .man-logo a {
        display: block;
    }

    .banner-under-data>p {
        margin: 16px 0 10px;
        font-size: 15px;
        line-height: 20px;
    }

    .main-banner .banner-under-data .tab-content {
        position: relative;
        z-index: 1;
    }

    .main-banner .banner-under-data .tab-content .tab-pane {
        padding: 8px;
        border-radius: 10px;
        border-top-left-radius: 0px;
        transition: all .6s ease-in-out;
    }

    .main-banner .banner-under-data .mian-inner-data-tab {
        flex-wrap: wrap;
    }

    .main-banner .banner-under-data .mian-inner-data-tab .selectMainDiv {
        width: 100%;
        margin: 0;
        padding: 5px;
    }

    .main-banner .banner-under-data .mian-inner-data-tab .selectMainDiv>div>div {
        font-size: 15px;
    }

    .main-banner .banner-under-data .mian-inner-data-tab .selectMainDiv>div>div svg {
        fill: rgba(61, 61, 61, 1);
        width: 18px;
        height: 18px;
    }

    .main-banner .banner-under-data .nav-pills {
        margin-bottom: 0;
        position: relative;
        z-index: 1;
        padding-top: 15px;
    }

    .main-banner .banner-under-data .nav-pills .nav-link {
        font-size: 14px;
        font-weight: 400;
        padding: 14px 10px 12px;
        line-height: 14px;
        animation-name: search-fade;
        animation-duration: .7s;
        transition: all .4s ease-in-out;
    }

    .main-banner .banner-under-data .nav-pills .nav-link.active {
        bottom: 0;
        align-items: center;
    }

    .main-banner .nav-pills .nav-item .nav-link.active i {
        margin-right: 5px;
    }

    /*.main-banner .banner-under-data .nav-pills .nav-link .btn-text-uppar {
    display: block;
}*/
    .main-banner .banner-under-data .tab-content .serch-btn svg {
        margin-right: 8px;
    }

    .main-banner .banner-under-data .tab-content .serch-btn {
        margin-top: 2px;
        font-size: 16px;
        font-weight: 500;
        min-height: 46px;
        line-height: 46px;
    }

    .view-catagirs-btn button {
        padding: 12px;
        font-size: 15px;
    }

    .mian-popular-banner .product-iteam .selling-product {
        margin-top: 20px;
    }

    .mian-popular-banner>.container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .mian-popular-banner .product-iteam {
        padding: 0;
        margin: 0;
    }

    .mian-popular-banner .product-iteam>div {
        padding: 0;
    }

    .mian-popular-banner .product-iteam .selling-product {
        margin-top: 20px;
    }

    .mian-popular-banner .product-iteam .selling-product h3 {
        line-height: normal;
        margin-bottom: 10px;
    }

    .mian-popular-banner .product-iteam .selling-product h6 {
        padding: 10px 0 0;
        margin-bottom: 5px;
    }

    .mian-popular-banner .product-iteam .selling-product .product-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .mian-popular-banner .product-iteam .selling-product .product-inner-image img {
        height: 132px;
        object-fit: contain;
    }

    .main-tires-section .tyre-section-txt {
        padding-top: 28px;
        line-height: normal;
        font-weight: 700;
        margin-bottom: 18px;
    }

    .main-tires-section>.container>.row {
        margin-left: -6px;
        margin-right: -6px;
        row-gap: 20px;
    }

    .main-tires-section>.container>.row>div {
        padding-left: 6px;
        padding-right: 6px;
    }

    .main-tires-section>.container>.row>div>a {
        display: inline-block;
        height: 100%;
    }

    .main-tires-section .card-tyr {
        height: 100%;
        margin: 0;
    }

    .main-tires-section .card-tyr .tyre-imge-upper {
        height: auto;
    }

    .main-tires-section .card-tyr .tyre-imge-upper .image-product-tyre {
        height: 155px;
        object-fit: cover;
    }

    .main-tires-section .card-tyr .cart-body h6 {
        margin-bottom: 7px;
    }

    .main-tires-section .card-tyr .cart-body h4 {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
    }

    .main-tires-section .card-tyr .cart-body .price-cart {
        column-gap: 15px;
    }

    .main-tires-section .card-tyr .cart-body .price-cart .cart-btn img {
        max-width: 40px;
        height: auto;
    }

    .view-catagirs-btn button {
        padding: 12px 30px !important;
        font-size: 15px;
    }

    .mian-serch-section {
        height: auto;
        padding: 30px 0;
    }

    .mian-serch-section .under-data-srech h2 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 5px;
        line-height: normal;
    }

    .mian-serch-section .under-data-srech h3 {
        font-weight: 400;
        font-size: 15px;
        line-height: normal;
    }

    .mian-serch-section .under-data-srech .secrch-bar {
        width: 100%;
        margin-top: 22px !important;
    }

    .mian-serch-section .under-data-srech .secrch-bar input {
        height: auto;
        padding: 10px 15px;
        font-size: 15px;
    }

    .mian-serch-section .under-data-srech .secrch-bar button {
        font-size: 15px;
        font-weight: 500;
        height: auto;
        padding: 5px 10px;
    }

    .mian-serch-section .under-data-srech .secrch-bar button svg {
        width: 25px;
        height: auto;
        margin-right: 6px;
        vertical-align: text-top;
    }

    .main-top-brands .tyre-section-txt {
        padding-top: 28px;
        line-height: normal;
        font-weight: 700;
        margin-bottom: 18px;
    }

    .main-top-brands .container .row {
        margin: 0;
    }

    .main-top-brands .row>div {
        margin: 0;
        max-width: 33.33%;
        background: transparent;
        margin-top: 0 !important;
        padding: 5px;
        height: auto !important;
    }

    .main-top-brands .row>div img.img-fluid {
        width: 100%;
        padding: 15px;
        max-width: 100px;
        background-color: #fff;
    }

    .main-shiiping-setion .providing img {
        width: 100%;
        max-width: 75px;
    }

    .main-shiiping-setion .providing .service_heading {
        font-weight: 500;
        margin: 15px 0 8px;
    }

    .main-shiiping-setion .providing {
        margin: 0 0 40px;
    }

    .fottor-data p {
        max-width: 100%;
        font-size: 14px;
        line-height: 20px;
    }

    .fottor-data h2 {
        font-size: 19px;
    }

    .fottor-data .menus_heads {
        margin-bottom: 2px;
    }

    .fottor-list li a {
        font-size: 15px;
    }

    .main-copy-right .social-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 15px;
    }

    .main-copy-right .social-icons i {
        margin: 0 0 0 0 !important;
    }

    .main-copy-right .col-lg-6 img {
        margin: 4px 4px 0 0 !important;
    }

    .categoriesSectionMain .categrories-txt {
        padding-top: 28px;
        line-height: normal;
        font-weight: 700;
    }

    .categoriesSectionMain>.container>.row {
        margin-left: -5px;
        margin-right: -5px;
    }

    .categoriesSectionMain>.container>.row>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .categoriesSectionMain .catigrious-name {
        padding-top: 8px;
        margin-bottom: 15px;
    }

    .categoriesSectionMain .categrories img {
        height: 110px !important;
        object-fit: cover;
    }

    .categoriesSectionMain .categrories {
        border: 1px solid #d8d8d8;
    }

    .navigation-list li span {
        position: relative;
    }

    .navigation-list li span i {
        position: absolute;
        right: 0;
    }

    .light-mega::after {
        left: 25px;
    }

    nav.navigation-bar.active-menu {
        left: 0;
    }

    .navigation-list li.has-child>a {
        pointer-events: none;
    }

    .navigation-list .has-child {
        padding-bottom: 0;
        transition: all .3s ease-in-out;
    }

    .navigation-list .has-child .mega-menu {
        max-height: 0vh;
        position: relative;
        pointer-events: none;
        overflow: hidden;
        opacity: 0;
        padding: 0 20px;
        margin-top: 15px;
        transition: all .3s ease-in-out;
        transform: none !important;
    }

    .navigation-list .has-child .mega-menu .menu-iteam {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .navigation-list .has-child .mega-menu.active-child {
        max-height: 100vh;
        opacity: 1;
        transition: all .4s ease-in-out;
        pointer-events: all;
    }

    .categoriesSectionMain p {
        text-align: center;
    }

    .categoriesSectionMain p strong {
        font-size: 16px !important;
    }

    .main-banner .bottom-data {
        padding-top: 10px;
    }

    .main-banner .bottom-data>p,
    .main-banner .bottom-data>a {
        width: 100%;
        max-width: 50%;
    }

    /* .main-banner .bottom-data p {
        margin-bottom: 10px;
        margin-top: 15px;
    } */
    .main-banner .bottom-data p {
        margin-bottom: 10px;
        margin-top: 15px;
        margin-left: 29px;
        margin-right: -10px;
    }

    .annusment-section {
        padding: 6px 0;
    }

    .main-section-lsting .pagination {
        flex-wrap: wrap;
        row-gap: 8px;
        justify-content: center;
    }

    .main-banner .nav-pills .nav-item .nav-link i {
        display: block;
        height: 16px;
    }

    .filters-sort-by p.px-5 {
        font-size: 15px;
        padding: 0 10px !important;
    }

    .filters-sort-by select {
        font-size: 15px;
    }

    .secrch-bar {
        width: 20%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .secrch-bar .searchIconClose {
        visibility: hidden;
        opacity: 0;
        transition: all .3s ease-in-out;
        display: block;
        position: fixed;
        top: 7px;
        right: 20px;
        pointer-events: none;
    }

    .secrch-bar .searchIconClose img {
        width: 15px;
        height: 15px;
    }

    .secrch-bar .searchIconOpen {
        display: block;
        position: relative;
        top: 2px;
    }

    .secrch-bar .searchIconOpen i {
        font-size: 19px;
        color: rgb(39, 39, 39);
    }

    .mian-header {
        position: relative;
        align-items: baseline;
        column-gap: 10px;
    }

    .main-header .secrch-bar form.form-search {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        background: #fff;
        z-index: 999;
        visibility: hidden;
        left: 100%;
        pointer-events: none;
        transition: all .3s ease-in-out;
        padding: 44px 15px 15px;
    }

    .main-header .secrch-bar .search_box_main form.form-search {
        position: static;
        background: #fff;
        transition: all .3s ease-in-out;
        padding: 0;
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    .main-header .secrch-bar.search-active form.form-search {
        visibility: visible;
        pointer-events: all;
        left: 0;
        align-items: baseline;
        border-radius: 0;
    }

    .main-header .secrch-bar .search_box_main form.form-search input {
        height: auto;
        padding: 13px 12px;
        font-size: 15px;
    }

    .main-header .secrch-bar form.form-search button {
        padding: 8px 10px 5px 8px;
        height: auto;
        font-size: 15px;
        line-height: 18px;
        top: 49px;
        right: 19px;
    }

    .main-header .secrch-bar form.form-search button svg {
        max-width: 24px;
        margin-right: 5px;
        position: relative;
        top: -2px;
    }

    .mian-header .secrch-bar.search-active .searchIconOpen {
        display: none;
    }

    .mian-header .secrch-bar.search-active .searchIconClose {
        visibility: visible;
        opacity: 1;
        z-index: 9999;
        pointer-events: all;
    }

    .main-banner .banner-under-data .tab-content .tab-pane.show.active {
        animation-name: search-fade;
        animation-duration: .7s;
    }

    .main-outer-filter {
        margin-top: 0;
        position: fixed;
        top: 0;
    }

    .filters-sort-by {
        align-items: center;
        margin: 0 0 10px;
        justify-content: space-between !important;
        flex-wrap: wrap;
        row-gap: 5px;
    }

    .filters-sort-by div:first-child {
        width: 100%;
    }

    .stockCheckbox .stockCheckboxUl .staggeredLi {
        font-size: 14px;
    }

    .stockCheckbox .stockCheckboxUl {
        padding-left: 0;
    }

    .stockCheckbox .stockCheckboxUl label.tgl-btn {
        height: 22px;
        width: 40px;
        background: green;
    }

    .stockCheckbox .stockCheckboxUl label.tgl-btn::after {
        width: 18px;
    }

    .filters-sort-by p.px-5 {
        font-size: 14px;
        color: #272727;
        padding: 0 !important;
    }

    .filters-sort-by select.form-control {
        font-size: 14px;
    }

    .car-selection-title strong {
        font-size: 22px !important;
    }

    .main-section-lsting .filter-for-mobile {
        position: relative;
    }

    .main-outer-filter.active-filter {
        left: 0;
    }

    body.home-layout {
        overflow: hidden;
    }

    .selectedFilters .clearFilterStyle {
        font-size: 13px;
    }

    .engle-imge-upper img {
        height: 125px !important;
        min-height: 125px !important;
    }

    button.hover-btn {
        font-size: 14px;
        padding: 10px 12px;
        width: 100%;
        max-width: 70%;
    }

    .Drivetrain-cart .cart-body {
        padding: 12px 10px 0 10px;
    }

    .Drivetrain-cart .cart-body h6 {
        margin-bottom: 7px;
    }

    .Drivetrain-cart .cart-body .description {
        font-size: 14px;
        font-weight: 600;
    }

    .Drivetrain-cart .price-cart .price.mt-2 {
        margin-top: 4px !important;
        overflow-wrap: anywhere;
        font-family: Roboto;
        line-height: normal;
        font-size: 14px;
    }

    .Drivetrain-cart .price-cart .price.mt-2 .Drivetrain-cart .cart-btn img {
        width: 100%;
        max-width: 55px;
        height: auto !important;
        object-fit: cover;
    }

    .Drivetrain-cart {
        margin-bottom: 0;
        height: 100%;
    }

    .filter-crose button.submit {
        background: #e81c2e;
        border-radius: 30px;
        padding: 2px 14px;
        color: #fff;
        margin-right: 18px;
        font-size: 13px;
        line-height: 20px;
    }

    .filter-crose button.abc img {
        width: 14px;
    }

    .main-outer-filter .aside-price-range-form .form-field:last-child input.form-control::placeholder {
        text-align: right;
    }

    .main-outer-filter .aside-price-range-form .form-field input.form-control::placeholder {
        font-size: 14px;
        font-weight: 600;
        color: #474747;
    }

    .main-outer-filter .aside-price-range-form .form-field input.form-control {
        border: none;
        background: none;
        padding: 0;
        font-size: 14px;
    }

    .main-section-lsting .filter-for-mobile>.row {
        row-gap: 20px;
    }

    .price-filter-text {
        padding-bottom: 8px;
    }

    .price-filter {
        padding: 15px 15px 10px 0;
        margin-bottom: 15px;
    }

    .price-filter #price-collapse-text {
        margin-bottom: 10px;
    }

    .main-outer-filter .aside-price-range-form .form-field {
        margin: 0 !important;
    }

    .main-header .man-logo img {
        width: 165px;
    }

    .category-main-section>.container>.row .col-sm-4.col-4 {
        width: 50%;
    }

    .category-main-section>.container>.row {
        row-gap: 20px;
    }

    .category-main-section .card {
        height: 100%;
        margin-top: 0 !important;
    }

    .category-main-section .card .row .col-md-4 {
        text-align: center;
    }

    .position-relative {
        margin-bottom: 0px;
        padding: 15px 0;
        text-align: center;
    }

    .category-main-section .card .row {
        padding: 0 !important;
    }

    .category-main-section .card .col-md-4 img {
        height: 125px;
        width: 65%;
        max-width: 100%;
        object-fit: cover;
    }

    .category-main-section .card .card-body {
        padding: 10px 12px;
    }

    .category-main-section .card .card-body .card-title {
        color: #272727;
        font-size: 14px;
        font-weight: 600;
    }

    .category-main-section .card .card-body span.text-danger.cursor_pointer {
        color: #e81c2e !important;
        font-size: 15px;
    }

    button.back-to-top {
        right: 35px;
    }

    button.back-to-top.show {
        right: 15px;
    }

    .add_vehicle_mobile button {
        background: none;
        color: #272727;
        margin: 0 5px;
    }

    .add_vehicle_mobile {
        display: block;
        position: relative;
    }

    .add_vehicle_mobile button i {
        vertical-align: -webkit-baseline-middle;
        font-size: 20px;
        position: relative;
        bottom: 4px;
    }

    .add_vehicle_mobile li.list-unstyled {
        box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 35%);
        font-size: 15px;
        column-gap: 3px;
        position: fixed;
        display: inline-flex;
        justify-content: center;
        right: 12px;
        top: 116px;
        left: 12px;
        align-items: center;
        white-space: nowrap;
        background: #fff;
        border-radius: 5px;
        padding: 10px 18px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all .3s ease-in-out;
        transform: translateY(-10px);
    }

    .add_vehicle_mobile .list-unstyled i {
        font-size: 18px;
        padding: 0 2px;
    }

    .add_vehicle_mobile.show li.list-unstyled {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transform: translateY(0px);
    }

    .add_vehicle_mobile li.list-unstyled::after {
        border: 7px solid;
        border-color: transparent transparent rgb(0 0 0 / 14%) transparent;
        z-index: 98;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        width: 0;
        right: 48px;
        top: -14px;
    }

    .add_vehicle_mobile li.list-unstyled::before {
        content: '';
        display: block;
        height: 0;
        position: absolute;
        width: 0;
        border: 6px solid;
        border-color: transparent transparent #ffffff transparent;
        z-index: 99;
        right: 82px;
        top: -12px;
    }

    .logo {
        width: 50%;
    }

    .wheelsPage .engle-imge-upper img {
        height: 175px !important;
    }

    .add_vehicle_mobile i.fa.fa-trash {
        color: #e81c2e;
    }

    .add_vehicle_mobile.show i {
        color: #e81c2e;
    }

    p.car-title {
        font-size: 20px;
        line-height: 26px;
    }

    .category-main-section>.container>.row h4 {
        margin-bottom: 0;
    }

    .carTitle {
        font-size: 20px;
        line-height: 26px;
    }

    .selectedFilters span.span-filter-Checkbox.m-1 {
        font-size: 12px;
        padding: 0px 8px 2px;
    }

    .selectedFilters span.span-filter-Checkbox.m-1 i::before {
        left: 5px;
        height: 11px;
        bottom: -2px;
    }

    .selectedFilters span.span-filter-Checkbox.m-1 i::after {
        left: 5px;
        height: 11px;
        bottom: -2px;
    }

    /*product details page*/
    .parformane-bar {
        height: auto;
    }

    section.slider-pernt {
        max-width: 100%;
    }

    .slider-pernt .slider {
        padding: 0px 0 15px 0px;
    }

    .slider__thumbs .swiper-slide.swiper-slide-visible {
        height: auto !important;
    }

    .parformane-bar .listing-parmofamne {
        line-height: 18px;
        padding: 2px 0 4px !important;
    }

    .parformane-bar .listing-parmofamne li {
        line-height: normal;
    }

    .listing-parmofamne li a {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
    }

    .main-product-data {
        padding-left: 0;
    }

    .product-name-details .prod_cat {
        font-size: 15px;
    }

    .producDescription {
        padding: 0;
    }

    section.similar-products-acc>.container>.col-lg-12>.row {
        row-gap: 20px;
    }

    section.similar-products-acc .customeChanges {
        width: 50% !important;
        margin: 0 !important;
    }

    .also-bought {
        padding-top: 40px;
    }

    .also-bought .Simila-txt {
        margin-bottom: 0;
    }

    .also-bought .card-tyr {
        margin: 0;
        height: 100%;
    }

    .also-bought>.row {
        row-gap: 20px;
    }

    .also-bought .card-tyr .tyre-imge-upper {
        height: auto;
    }

    .also-bought .tyre-imge-upper img {
        height: 125px !important;
        max-width: 100%;
        overflow: hidden;
        object-fit: cover;
    }

    .also-bought .cart-body {
        padding: 12px 10px 10px 10px;
    }

    .also-bought .cart-body .also_bought_cat_name {
        margin-bottom: 7px;
    }

    .also-bought .cart-body h4 {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.2;
    }

    .also-bought .cart-body .price-cart {
        column-gap: 8px;
    }

    .also-bought .price-cart .cart-btn img {
        width: 100%;
        max-width: 55px;
        height: auto;
        object-fit: cover;
    }

    .main-filter-resposive button.mobile-filter-btn svg {
        width: 18px;
    }

    .main-product-data .quantity {
        display: flex;
        flex-wrap: wrap;
        row-gap: 14px;
        column-gap: 15px;
    }

    .main-product-data .quantity .incres {
        width: calc(40% - 7.5px) !important;
        text-align: center;
        margin-right: 0;
        white-space: nowrap;
    }

    .main-product-data .quantity .incres .value-button {
        width: 30px;
    }

    .main-product-data .quantity .cart {
        width: 100%;
        max-width: calc(60% - 7.5px);
    }

    .main-product-data .quantity .cart button {
        margin-top: 0;
        width: 100%;
        text-align: center;
        padding: 15px;
    }

    .main-product-data .quantity .Finance {
        width: 100%;
    }

    .main-product-data .quantity .Finance button {
        margin-top: 0;
        width: 100%;
        text-align: center;
    }

    .category-main-section .card:hover {
        border: 1px solid rgb(0 0 0 / 28%);
        box-shadow: 0px 0px 3px 0px #0000004f;
    }

    body.overflow_vehicle .fade_background {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(0 0 0 / 40%);
        z-index: 1500;
    }

    body.overflow_vehicle .add_vehicle_mobile.show {
        z-index: 1600;
    }

    body.overflow_vehicle {
        overflow: hidden;
    }

    body.modal-open .modal-backdrop {
        z-index: 1601;
    }

    body.modal-open .modal.show {
        z-index: 1602;
    }

    .similar-products-acc .Simila-txt {
        margin-bottom: 0;
    }

    .about-us-content .content_first h2 {
        font-size: 22px;
        line-height: 35px;
    }

    .about-us-content .content_second h3 {
        font-size: 22px;
    }

    .about-us-content {
        padding: 0;
    }

    .contact_us_addr {
        padding: 50px 0;
    }

    .contact_us_addr .addr_title {
        font-size: 25px;
        line-height: 35px;
    }

    .contact_us_addr .main>.row {
        row-gap: 15px;
    }

    .contact_form {
        padding: 50px 0px 10px;
    }

    .contact_form .main .form_title {
        font-size: 25px;
    }

    .contact_form .main>p {
        font-size: 16px;
        line-height: 25px;
    }

    .contact_form .main form label.form_field input,
    .contact_form .main form label.form_field textarea {
        padding: 15px;
    }

    .contact_form .main form input[type="submit"] {
        padding: 16px;
    }

    .main-banner .nav-pills #pills-profile-tab i::before {
        content: '';
        background-image: url('../public/images/wheel-gray.png');
        width: 16px;
        height: 16px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        top: -1px;
    }

    .main-banner .nav-pills #pills-profile-tab.active i::before {
        background-image: url('../public/images/wheel-white.png');
        top: -1px;
    }


    .main-banner .nav-pills #pills-contact-tab i::before {
        content: '';
        background-image: url('../public/images/Tires.png');
        width: 16px;
        height: 16px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        top: -1px;
    }

    .main-banner .nav-pills #pills-contact-tab.active i::before {
        background-image: url('../public/images/Tire_white.png');
        top: -1px;
    }


    .filter-crose.button-bottom {
        position: fixed;
        right: 0;
        bottom: 0;
        width: 100%;
        padding-right: 20px;
        padding-bottom: 15px;
        padding-top: 15px;
        background: #fff;
        left: 100%;
        transition: .4s;
    }

    .active-filter .filter-crose.button-bottom {
        left: 0;
    }

    .filter-crose button.reset {
        background: #fff;
        border-radius: 30px;
        padding: 2px 14px;
        margin-right: 8px;
        font-size: 13px;
        line-height: 20px;
        border: 1px solid #e81c2e;
        color: #e81c2e;
    }

    .cart-box .img_content {
        flex-wrap: wrap;
    }

    .cart-main .cart-box {
        padding: 10px;
    }

    .cart-main .cart-box .close_btn {
        top: 10px;
        right: 10px;
    }

    .img_content .product_image {
        border-right: none;
        border-bottom: 1px solid #eee;
        width: 100%;
        max-width: 100%;
        height: auto;
        padding: 0;
        text-align: center;
        margin-bottom: 10px;
    }

    .img_content .product_image img {
        max-width: 170px;
        padding: 0;
    }

    .img_content .product_details {
        width: 100%;
        padding: 0;
    }

    .product_details .title {
        font-size: 20px;
        margin-bottom: 6px;
        padding-right: 0;
    }

    .product_details .list ul li {
        font-size: 14px;
    }

    .cart-box .qty .qty_text {
        font-size: 16px;
    }

    .cart-box .quantity .value-button {
        height: 30px;
        padding: 8px 0;
    }

    .cart-box .quantity input#number {
        font-size: 20px;
    }

    .cart-box .qty .update_cart a {
        height: 30px;
        line-height: 28px;
    }

    .cart-box .qty {
        column-gap: 10px;
    }

    .cart-main .cart_title .title {
        padding-top: 15px;
        font-size: 22px;
        margin-bottom: 15px;
    }

    .filters-sort-by.mainDivStaggered {
        flex-direction: column;
        align-items: start;
        row-gap: 5px;
    }

    .mainDivStaggered .textShowing {
        justify-content: space-between;
        width: 100%;
    }

    .wheelsPage.rightSide p {
        overflow-wrap: anywhere;
    }

    .wheelsPage.rightSide hr {
        margin: 6px 0;
    }

    .wheelsPage.rightSide p strong.text-danger {
        font-size: 16px;
        margin-right: 2px;
    }

    .staggeredCheckbox .list-filter .staggeredLi {
        font-size: 16px;
    }

    .wheelsPage.leftSide .cart-body p {
        white-space: normal;
    }

    .car-selection-title {
        column-gap: normal;
        flex-direction: column;
        align-items: start;
        row-gap: 5px;
    }

    .slider__thumbs .swiper-slide-thumb-active {
        border-color: #E81C2E !important;
    }

    .selectMainDiv .css-13cymwt-control {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .blogs_main {
        margin: 50px 0 0;
    }

    .blogs_main .blog_row {
        margin: 0;
    }

    .blog_row .blo_col {
        max-width: 100%;
        padding: 0 10px;
        margin-bottom: 40px;
    }

    .main-product-data .mainAttributes>.row>.col-md-6 {
        max-width: 50%;
    }

    .blog_detail_main {
        margin: 30px 0 20px;
    }

    .blog_detail_main .blog_title {
        font-size: 22px;
    }

    .blog_detail_main .container {
        padding: 0 20px;
    }

    .blog_content .main_title {
        font-size: 20px;
    }

    .blog_content .sub_headings {
        font-size: 18px;
        margin: 10px 0;
    }

    .mian-header .secrch-bar button img.wheel_gif_icon {
        width: 24px;
        position: relative;
        top: -2px;
    }

    .product-data span.product-cate {
        padding: 10px 0 0;
        margin-bottom: 5px;
    }

    .card-tyr .top_sell_cat {
        margin-bottom: 7px;
    }

    .main-tires-section .card-tyr .cart-body .top_sell_prod_title {
        font-size: 14px;
        line-height: 14px;
    }

    .main-tires-section .card-tyr .cart-body .price .top_sell_prod_price {
        font-size: 17px;
        line-height: 14px;
    }

    .main-tires-section .top_sell_cat_text {
        margin-bottom: 7px;
        font-weight: 400;
    }

    .txt-main-listing {
        font-size: 40px;
    }

    .siderr-bar .shipingg {
        margin-top: 50px;
    }

    .main-banner button.serch-btn img.wheel_gif_icon {
        margin-right: 5px;
    }

    .mian-serch-section .secrch-bar button img.wheel_gif_icon {
        width: 26px;
        height: 18px;
        margin-right: 5px;
        position: relative;
        top: 0;
    }

    .main-top-brands .brand-filters>div {
        max-width: 100%;
        padding: 0;
    }

    .main-top-brands .brand-filters ul.alphabet-list {
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
    }

    .main-top-brands .brand-filters .alphabet-list li {
        width: 100%;
        max-width: 100%;
        padding: 1px;
        font-size: 12px;
        line-height: 28px;
    }

    .main-top-brands .text-search .text {
        font-size: 16px;
        padding-right: 20px;
    }

    .main-top-brands .searchInput input {
        padding: 10px 12px;
    }

    .main-top-brands .searchInput input::placeholder {
        font-size: 14px;
    }

    .main-top-brands .section-heading {
        margin: 0;
        font-size: 22px;
        padding: 5px;
        position: relative;
        z-index: 0;
    }

    .main-top-brands .section-heading::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 28px;
        background: #f2f2f2;
        z-index: -1;
    }

    .main-top-brands .section-heading::after {
        content: '';
        position: absolute;
        width: calc(100% - 25px);
        height: 1px;
        background: #212529;
        top: 50%;
        transform: translateY(-1px);
        left: auto;
        right: auto;
        z-index: -2;
    }

    .main-top-brands>.container .row {
        row-gap: 0;
    }

    .Drivetrain-cart .price .price_tag {
        font-size: 17px;
        line-height: 14px;
    }

    .product_details .qty_price {
        flex-wrap: wrap;
        row-gap: 5px;
    }

    .cart_on_mobile {
        display: inline-block;
        margin-right: 0;
    }

    .cart_on_mobile a.icon {
        color: #272727;
        text-decoration: none;
        margin: 0;
        position: relative;
    }

    .cart_on_mobile .cartValue {
        font-size: 10px;
        font-weight: 700;
        position: absolute;
        top: -7px;
        left: 13px;
        background: #e81c2e;
        border-radius: 50%;
        border: 1px solid #e81c2e;
        width: 16px;
        height: 16px;
        color: #fff !important;
        line-height: 13px;
        text-align: center;
    }

    .term-banner {
        background-position: 35%;
    }

    .warnnty-text h2 {
        font-size: 25px;
        padding-bottom: 15px;
        margin-top: 35px;
    }

    .check-text {
        margin-bottom: 15px;
        line-height: 22px;
        align-items: flex-start;
    }

    .check-text.text_link {
        flex-wrap: wrap;
        padding-left: 36px;
        column-gap: 5px;
    }

    .check-text.text_link .invisible {
        display: none;
    }

    .check-text.text_link .contact-anker {
        padding-left: 0;
    }

    .dropdown-category button.dropdown-toggle {
        height: 40px !important;
    }

    .dropdown-category .dropdown-menu {
        top: 108px;
        left: 24px;
    }

    .filters-sort-by.mainDivStaggered .textShowing {
        flex-wrap: wrap;
        row-gap: 5px;
    }

    .wheelsPageSectioin .price-filter .tgl-btn {
        height: 22px;
        width: 40px;
        padding: 1px;
    }

    .wheelsPageSectioin .price-filter .tgl-btn::after {
        width: 18px;
        background: #a5a5a5;
    }

    .wheelsPageSectioin .price-filter .tgl-btn {

        border: 1px solid green;
        background: green;
    }

    .wheelsPageSectioin .price-filter .tgl-btn:checked {

        border: 1px solid red;
        background: red;
    }

    .stockCheckbox .stockCheckboxUl .staggeredLi span {
        min-width: 125px;
        display: inline-block;
    }

    .search_opener .search_box_main {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .search_box_main .result-div {
        height: 100%;
    }

    body.open_search {
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .search_box_main .searc_heading p {
        font-size: 20px;
        margin-bottom: 12px;
    }

    .search_banners {
        padding-top: 20px;
    }

    .search_banners .searc_heading.second p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .search_opener .loader-line {
        max-width: 100%;
        min-width: 100%;
        left: auto;
        right: 0;
    }

    .search_banners .banner_box {
        column-gap: 5px;
    }

    .search_banners .banner_img img {
        max-width: 45px;
        min-width: 45px;
        min-height: 45px;
        max-height: 45px;
    }

    .search_banners .banner_text h2 {
        font-size: 14px;
        padding: 0 2px;
    }

    .main-header .secrch-bar .search_box_main form.form-search input::placeholder {
        font-size: 15px;
    }

    .search_box_main .result-div .produc-list .image img {
        max-width: 65px;
        min-width: 65px;
        min-height: 65px;
        max-height: 65px;
    }

    .search_opener.active a.searchIconClose {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
        z-index: 1;
    }

    .navigation-list .has-child .menu-list-mega li {
        margin-top: 5px;
    }

    .filter-and-brands {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row-reverse;
        margin-top: 20px;
    }

    .filter-and-brands .brand-filters {
        width: 100%;
        max-width: 10%;
        flex-basis: 10%;
        margin-top: 24px !important;
        position: sticky;
        top: -10px;
    }

    .filter-and-brands .brands_images {
        width: 100%;
        max-width: 90%;
        flex-basis: 90%;
    }

    .fitmet-type span.title {
        font-size: 14px;
        color: #272727;
        padding: 0 !important;
        font-family: 'Roboto';
        margin-bottom: 2px;
        display: block;
        font-weight: 600;
    }

    .fitmet-type {
        margin-bottom: 5px;
    }

    .fitmet-type select.form-control {
        font-size: 14px;
        font-family: Roboto;
        font-weight: 400;
        text-align: left;
        color: #272727;
        box-shadow: none !important;
    }

    .price-filter.desktop-only {
        display: none;
    }

    .Drivetrain-cart .price-cart {
        column-gap: 0;
    }

    /* .Drivetrain-cart {
        padding-bottom: 45px;
    } */

    .Drivetrain-cart .cart-body .price-cart:last-child {
        bottom: 0;
    }

    .wheelsPage.rightSide .priceWheel span:not(:last-child) {
        margin-right: 7px;
    }

    .nav-item.user-mobile i {
        font-size: 22px;
        vertical-align: bottom;
        color: #272727 !important;
    }

    .nav-item.user-mobile {
        margin: 0 2px;
    }

    .nav-item.user-mobile {
        display: block;
    }

    .staggeredCheckbox .price-filter .tgl-btn::after {
        background: #fff;
    }

    .wheelsPage.rightSide .wheelSku {
        width: 100%;
        position: relative;
        /* padding-top: 20px; */
    }

    /* .wheelsPage.rightSide .wheelSku strong {
        top: 0;
        position: absolute;
    } */
    .wheelsPage.rightSide {
        padding: 10px 8px;
        height: 100%;
        cursor: pointer;
    }
    .wheelsPage.rightSide p strong {
        margin-right: 5px;
    }
    .wheelsPage p {
        font-size: 13px;
    }
    .finance-section .container {
        width: 90%;
        /* max-width: 1080px; */
        /* margin: auto; */
        /* position: relative; */
    }

    .finance-section {
        padding: 30px 0 20px;
    }

    .finance-section .content h1 {
        font-size: 18px;
        margin-bottom: 15px;

    }

    .finance-section .content p {
        font-size: 13px;
        margin-bottom: 25px;
        line-height: normal;
    }

    .page_btn_cmmncls {

        font-size: 15px;

    }

    .finance-logo {
        text-align: center;
        padding: 15px 0 0;
    }

    .shop-section .shop-with h2 {
        margin-bottom: 20px;
    }

    .finance-logo h2 {
        font-size: 16px;
        margin-bottom: 20px;
        line-height: normal;
        color: #333;
        font-weight: 700;
        text-align: center;
    }

    .shop-section {
        text-align: center;
        padding: 15px 0;
    }

    .shop-section .shop-with {
        padding: 10px 0;
        text-align: center;
    }

    .shop-section .step-boxes .box .box-content a {
        background: #E81C2E;
        color: #fff;
        display: inline-block;
        padding: 5px 21px;
        font-size: 13px;
        line-height: normal;
        text-transform: uppercase;
        margin-top: 20px;
    }

    .shop-section .step-boxes {
        display: block;
        margin-top: 25px;
    }

    .shop-section .step-boxes .box {
        max-width: 100%;
        width: 100%;
        border: 1px solid #000;
        border-bottom: 0;
    }

    .shop-section .step-boxes .box:first-child {
        border-right: 1px solid black;
    }

    .shop-section .step-boxes .box:last-child {
        border-bottom: 1px solid black;
    }

    .shop-with img {
        width: 110px;
        height: auto;
    }

    .shop-section h2 {
        font-size: 18px;
        line-height: normal;
        color: #333;
        font-weight: 700;
        text-transform: uppercase;
    }

    .shop-section .step-boxes .box .box-content h3 {
        font-size: 18px;
        line-height: 16px;
        color: #2c2c2c;
        margin-bottom: 15px;
    }

    .shop-section .step-boxes .box .box-content p {
        font-size: 15px;
        color: #2c2c2c;
        line-height: 16px;
        margin-bottom: 0px;
    }

    .user-template {
        position: absolute;
        right: 0;
        top: 60px;
        background: rgb(244 241 241);
        padding: 20px;
        border-radius: 5px;
        z-index: 1;
    }

    @keyframes search-fade {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes marquee {
        0% {
            left: 0%;
        }

        100% {
            left: -250%;
        }
    }



    /* new changes 25-09-2023 */
    #prices {
        height: fit-content !important;
    }

    #prices p {
        margin: 0 !important;
        padding: 0 !important;
    }

    /* new changes 27-09-2023 */

    .tiresCards .Drivetrain-cart {
        padding: 0;
    }

    .tiresCards .Drivetrain-cart .cart-btn img {
        object-fit: contain;
    }

    .tiresCards .Drivetrain-cart .cart-btn img {
        height: 70px;
    }

    .tiresCards .Drivetrain-cart .cart-body .price-cart:last-child {
        position: relative;
    }

    .tiresCards .Drivetrain-cart .cart-body .price-cart:last-child {
        position: none;
        left: 0;
        bottom: 1px;
        right: 14px;
    }

    .main-outer-filter .price-filter-fitment {
        display: none;
    }

    #react-select-2-listbox,
    #react-select-3-listbox,
    #react-select-4-listbox,
    #react-select-5-listbox {
        position: fixed;
        top: 25%;
        left: 0;
        right: 25%;
        bottom: 15px;
        width: 100%;
        height: 100%;
        background: transparent;
        padding: 0 15px 0 15px;
    }

    #react-select-2-listbox>*,
    #react-select-3-listbox>*,
    #react-select-4-listbox>*,
    #react-select-5-listbox>* {
        background: #fff;
        min-height: 70vh;
    }


}


@media screen and (max-width: 575px) {

    button.back-to-top.show {
      
        right: 90px;
       
    }

    .five_percent_homepage img {
        width: 54%;
    }

    .five_percent_homepage {
        right: 4px;
        text-align: right;
    }
    .price_in_card_one{
        font-size: 13px;
    }
    .price_in_card_two{
        font-size: 15px !important;
    }
    .price_card_acc{
        display: block;
    }

    .discount_message {
        color: red;
        font-size: 12px;
        font-weight: 600;
        text-decoration: underline;
        margin-left: 13%;
        text-decoration-skip-ink: none;
        text-underline-offset: 6px;
        font-family: Roboto;
    }
    span.discount_message {
        display: block;
        margin: 0;
    }
    
    .cart_title {
        margin: 15px 0;
    }

}

@media screen and (max-width: 420px) {

    button.back-to-top.show {
      
        right: 90px;
       
    }

    .five_percent_homepage img {
        width: 40%;
    }
    h2.text-center.txt-main-listing {
        position: relative;
    }
    
    .five_percent_products img {
        width: 40%;
    }
    
    .five_percent_products {right: 4px;top: 260px;text-align: right;}


    .price_in_card_one{
        font-size: 13px;
    }
    .price_in_card_two{
        font-size: 15px !important;
    }
    .price_card_acc{
        display: block;
    }

}




.add-info .information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.add-info .information .name p {
    margin: 0;
}
.add-info .information .download-btn a {
    display: flex;
    align-items: center;
}